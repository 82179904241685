<template>
  <div class="order-manage">
    <b-container>
      <b-card style="margin-bottom: 10px;">
        <b-row>
          <b-col>
            <label for="input-orderNo">Order No:</label>
            <b-input-group size="sm">
              <b-form-input id="input-orderNo" v-model="search.orderNo" type="search"
                            placeholder="Order No"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <label for="input-orderDate">Order Date:</label>
            <b-form-datepicker id="input-orderDate" size="sm" v-model="search.orderDate"></b-form-datepicker>
          </b-col>
          <b-col>
            <label for="input-deliveryDate">Delivery Date:</label>
            <b-form-datepicker id="input-deliveryDate" size="sm" v-model="search.deliveryDate"></b-form-datepicker>
          </b-col>
          <b-col cols="3" align-self="center" class="text-center">
            <b-button-group>
              <b-button variant="info" @click="handleSearch">Search</b-button>
              <b-button @click="handleReset">Reset</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-card>
      <b-table striped hover :sticky-header="tableHeight+'px'" head-variant="light" table-variant="light"
               :busy.sync="table.isBusy" :fields="table.fields" :items="table.items" show-empty
               :style="{height: tableHeight+'px'}">
        <template #cell(no)="row">
          {{ row.index + 1 }}
        </template>
        <template #head(orderNo)="scope">
          <div class="text-nowrap">{{ scope.label }}</div>
        </template>
        <template #cell(height)="row">
          {{ row.value }} {{ row.item.heightUnit }}
        </template>
        <template #cell(weight)="row">
          {{ row.value }} {{ row.item.weightUnit }}
        </template>
        <template #cell(statusTxt)="row">
          <b-icon-minecart-loaded v-if="row.item.status===0"/>
          <b-icon-easel v-else-if="row.item.status===1"/>
          <b-icon-inboxes v-else-if="row.item.status===2"/>
          <b-icon-star v-else-if="row.item.status===3"/>
          {{ row.value }}
        </template>
        <template #cell(action)="row">
          <b-button variant="link" size="sm" @click="handleDetail(row)">
            detail
          </b-button>
          <b-button variant="link" size="sm" @click="showStatusModel(row)">
            status
          </b-button>
        </template>
        <template #row-details="row">
          <!-- detail -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">DETAIL</span>
            </template>
            <template v-if="order.orderDetail[row.item.id] && order.orderDetail[row.item.id].length>0">
              <b-row v-for="od in order.orderDetail[row.item.id]" :key="od.id" align-v="center">
                <b-col cols="1">
                  <label class="title">CVP:</label>
                  <b-avatar size="sm" :text="od.cvp"></b-avatar>
                </b-col>
                <b-col>
                  <label class="title">Fabric:</label>
                  {{ od.fabric }}
                </b-col>
                <b-col>
                  <label class="title">bodyLining:</label>
                  {{ od.bodyLining }}
                </b-col>
                <b-col>
                  <label class="title">sleeveLining:</label>
                  {{ od.sleeveLining }}
                </b-col>
                <b-col>
                  <label class="title">remark:</label>
                  {{ od.remark }}
                </b-col>
              </b-row>
            </template>
            <template v-else>
              Abnormal Order Information!
            </template>
          </b-card>
          <!-- style -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">STYLE</span>
            </template>
            <template v-if="order.style[row.item.id]">
              <b-tabs>
                <b-tab title="Jacket" v-if="clothingTypeArr.indexOf('C')>-1">
                  <template v-for="os in order.style[row.item.id]['jacket']">
                    <b-row :key="row.item.id+'_c_'+os.name" style="padding: 5px;">
                      <b-col cols="2">
                        <label class="title">{{ os.name }}:</label>
                      </b-col>
                      <b-col>
                        {{ os.value }}
                      </b-col>
                    </b-row>
                  </template>
                </b-tab>
                <b-tab title="Pant" v-if="clothingTypeArr.indexOf('P')>-1">
                  <template v-for="os in order.style[row.item.id]['pant']">
                    <b-row :key="row.item.id+'_p_'+os.name" style="padding: 5px;">
                      <b-col cols="2">
                        <label class="title">{{ os.name }}:</label>
                      </b-col>
                      <b-col>
                        {{ os.value }}
                      </b-col>
                    </b-row>
                  </template>
                </b-tab>
                <b-tab title="Vest" v-if="clothingTypeArr.indexOf('V')>-1">
                  <template v-for="os in order.style[row.item.id]['vest']">
                    <b-row :key="row.item.id+'_v_'+os.name" style="padding: 5px;">
                      <b-col cols="2">
                        <label class="title">{{ os.name }}:</label>
                      </b-col>
                      <b-col>
                        {{ os.value }}
                      </b-col>
                    </b-row>
                  </template>
                </b-tab>
              </b-tabs>
            </template>
            <template v-else>
              Abnormal Order Information!
            </template>
          </b-card>
          <!-- embroidery -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">EMBROIDERY</span>
            </template>
            <template v-if="order.embroidery[row.item.id] && order.embroidery[row.item.id].length>0">
              <b-row v-for="oe in order.embroidery[row.item.id]" :key="oe.id" align-v="center">
                <b-col>
                  <label class="title">Letter:</label>
                  {{ oe.letter }}
                </b-col>
                <b-col>
                  <label class="title">Position:</label>
                  {{ oe.position }}
                </b-col>
                <b-col>
                  <label class="title">Font:</label>
                  {{ oe.font }}
                </b-col>
                <b-col>
                  <label class="title">Color:</label>
                  {{ oe.color }}
                </b-col>
              </b-row>
            </template>
            <template v-else>
              Abnormal Order Information!
            </template>
          </b-card>
          <!-- measurement -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">MEASUREMENT</span>
            </template>
            <template v-if="order.measurement[row.item.id]">
              <b-tabs>
                <b-tab title="Jacket" v-if="clothingTypeArr.indexOf('C')>-1">
                  <b-row style="margin-top: 10px;">
                    <b-col>
                      <b-card header-bg-variant="transparent">
                        <template slot="header">
                          <span>Body</span>
                        </template>
                        <template v-for="om in order.measurement[row.item.id]['jacket']['body']"
                                  style="padding-top: 10px;">
                          <b-row :key="row.item.id+'_c_'+om.measurePart" style="padding: 5px;">
                            <b-col>
                              <label class="title">{{ om.measurePart }}:</label>
                            </b-col>
                            <b-col class="text-center">
                              {{ om.measureValue }} {{ om.measureUnit }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-card>
                    </b-col>
                    <b-col>
                      <b-card header-bg-variant="transparent">
                        <template slot="header">
                          <span>Finished</span>
                        </template>
                        <template v-for="om in order.measurement[row.item.id]['jacket']['finished']"
                                  style="padding-top: 10px;">
                          <b-row :key="row.item.id+'_c_'+om.measurePart" style="padding: 5px;">
                            <b-col>
                              <label class="title">{{ om.measurePart }}:</label>
                            </b-col>
                            <b-col class="text-center">
                              {{ om.measureValue }} {{ om.measureUnit }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Pant" v-if="clothingTypeArr.indexOf('P')>-1">
                  <b-row style="margin-top: 10px;">
                    <b-col>
                      <b-card header-bg-variant="transparent">
                        <template slot="header">
                          <span>Body</span>
                        </template>
                      </b-card>
                      <template v-for="om in order.measurement[row.item.id]['pant']['body']">
                        <b-row :key="row.item.id+'_p_'+om.measurePart" style="padding: 5px;">
                          <b-col>
                            <label class="title">{{ om.measurePart }}:</label>
                          </b-col>
                          <b-col class="text-center">
                            {{ om.measureValue }} {{ om.measureUnit }}
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                    <b-col>
                      <b-card header-bg-variant="transparent">
                        <template slot="header">
                          <span>Finished</span>
                        </template>
                        <template v-for="om in order.measurement[row.item.id]['pant']['finished']">
                          <b-row :key="row.item.id+'_p_'+om.measurePart" style="padding: 5px;">
                            <b-col>
                              <label class="title">{{ om.measurePart }}:</label>
                            </b-col>
                            <b-col class="text-center">
                              {{ om.measureValue }} {{ om.measureUnit }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Vest" v-if="clothingTypeArr.indexOf('V')>-1">
                  <b-row style="margin-top: 10px;">
                    <b-col>
                      <b-card header-bg-variant="transparent">
                        <template slot="header">
                          <span>Body</span>
                        </template>
                        <template v-for="om in order.measurement[row.item.id]['vest']['body']">
                          <b-row :key="row.item.id+'_v_'+om.measurePart" style="padding: 5px;">
                            <b-col>
                              <label class="title">{{ om.measurePart }}:</label>
                            </b-col>
                            <b-col class="text-center">
                              {{ om.measureValue }} {{ om.measureUnit }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-card>
                    </b-col>
                    <b-col>
                      <b-card header-bg-variant="transparent">
                        <template slot="header">
                          <span>Finished</span>
                        </template>
                        <template v-for="om in order.measurement[row.item.id]['vest']['finished']">
                          <b-row :key="row.item.id+'_v_'+om.measurePart" style="padding: 5px;">
                            <b-col>
                              <label class="title">{{ om.measurePart }}:</label>
                            </b-col>
                            <b-col class="text-center">
                              {{ om.measureValue }} {{ om.measureUnit }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </template>
            <template v-else>
              Abnormal Order Information!
            </template>
          </b-card>
          <!-- body description -->
          <b-card border-variant="light">
            <template slot="header">
              <b-icon-blockquote-right/>
              <span class="header-title">BODY DESCRIPTION</span>
            </template>
            <template v-if="order.bodyDescription[row.item.id]">
              <b-row v-if="clothingTypeArr.indexOf('C')>-1">
                <b-col>
                  <label class="title">FRONT SHOULDER:</label>
                  {{ order.bodyDescription[row.item.id]['frontShoulder'] }}
                </b-col>
                <b-col>
                  <label class="title">BACK BODY:</label>
                  {{ order.bodyDescription[row.item.id]['backBody'] }}
                </b-col>
                <b-col>
                  <label class="title">LEFT SHOULDER:</label>
                  {{ order.bodyDescription[row.item.id]['leftShoulder'] }}
                </b-col>
                <b-col>
                  <label class="title">RIGHT SHOULDER:</label>
                  {{ order.bodyDescription[row.item.id]['rightShoulder'] }}
                </b-col>
              </b-row>
              <b-row v-if="clothingTypeArr.indexOf('C')>-1">
                <b-col>
                  <label class="title">ARM:</label>
                  {{ order.bodyDescription[row.item.id]['arm'] }}
                </b-col>
                <b-col>
                  <label class="title">BELLY:</label>
                  {{ order.bodyDescription[row.item.id]['belly'] }}
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
              <b-row v-if="clothingTypeArr.indexOf('P')>-1">
                <b-col>
                  <label class="title">PANT-HIP:</label>
                  {{ order.bodyDescription[row.item.id]['pantHip'] }}
                </b-col>
              </b-row>
            </template>
          </b-card>
        </template>
        <template #empty="scope">
          <p>{{ scope.emptyText }}</p>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <span style="color: white;">共 <b>{{ table.totalRows }}</b> 条</span>
        </b-col>
        <b-col>
          <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                        align="right" style="margin-bottom:initial;"></b-pagination>
        </b-col>
      </b-row>
    </b-container>

    <!-- modal:status -->
    <b-modal id="modal-status" title="Status Select" ok-only ok-title="确定" @ok.prevent="handleStatus">
      <b-overlay :show="current.loading" rounded="sm">
        <b-row>
          <b-col cols="3">
            <label style="font-weight: bold;">Current Status:</label>
          </b-col>
          <b-col>
            {{ current.order.statusTxt }}
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="3">
            <label style="font-weight: bold;">Change Status:</label>
          </b-col>
          <b-col>
            <b-form-select size="sm" v-model="current.order.status"
                           :options="selectOptions.orderStatus"></b-form-select>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {selectOptions} from "../assets/js/resource";
import {type2ClothingTypeArr} from "../assets/js/util";

export default {
  name: "orderManage",
  data() {
    return {
      search: {
        orderNo: '',
        orderDate: '',
        deliveryDate: ''
      },
      table: {
        isBusy: false,
        fields: [
          {key: 'no', label: 'No'},
          {key: 'orderNo', label: 'OrderNo', stickyColumn: true, sortable: true, sortDirection: 'desc'},
          {key: 'orderDate', label: 'OrderDate', sortable: true, sortDirection: 'desc'},
          {key: 'type', label: 'Type', sortable: true, sortDirection: 'desc'},
          {key: 'clientName', label: 'ClientName', sortable: true, sortDirection: 'desc'},
          {key: 'height', label: 'Height', sortable: true, sortDirection: 'desc'},
          {key: 'weight', label: 'Weight', sortable: true, sortDirection: 'desc'},
          {key: 'deliveryDate', label: 'DeliveryDate', sortable: true, sortDirection: 'desc'},
          {key: 'statusTxt', label: 'Status', sortable: true, sortDirection: 'desc'},
          {key: 'action', stickyColumn: true}
        ],
        items: [],
        totalRows: 0,
        perPage: 10,
        currentPage: 1
      },
      clothingTypeArr: [],
      order: {
        orderDetail: {},
        style: {},
        embroidery: {},
        measurement: {},
        bodyDescription: {}
      },
      current: {
        loading: false,
        order: {
          id: '',
          status: '',
          statusOld: ''
        }
      },
      selectOptions: selectOptions
    }
  },
  computed: {
    tableHeight: function () {
      return window.innerHeight - 20 - 18 - 28 - 109 - 16 - 33 - 23;
    }
  },
  methods: {
    loadPage() {
      this.table.items = [];
      this.table.totalRows = 0;

      this.$axios.get('order', Object.assign({}, {
            perPage: this.table.perPage,
            currentPage: this.table.currentPage
          }, this.search)
      )
          .then((res) => {
            if (res.s === 0) {
              let data = res.data;

              // table赋值
              this.table.items = data.records;
              this.table.perPage = data.size;
              this.table.currentPage = data.current;
              this.table.totalRows = data.total;
            }
          })
          .catch(err => {
            console.error(err);
          });
    },
    handleSearch() {
      this.loadPage();
    },
    handleReset() {
      this.search.orderNo = '';
      this.search.orderDate = '';
      this.search.deliveryDate = '';
    },
    handleDetail(row) {
      if (!row.detailsShowing) {
        this.clothingTypeArr = type2ClothingTypeArr(row.item.type);
        if (this.clothingTypeArr.length <= 0) {
          this.$bvToast.toast('订单数据异常', {
            title: '系统提示',
            variant: 'danger',
            solid: true
          });
          return;
        }

        let rowId = row.item.id;

        this.$axios.get('order/' + rowId, {})
            .then((res) => {
              if (res.s === 0) {
                let data = res.data;

                this.order.orderDetail[rowId] = data.orderDetail;
                this.order.style[rowId] = data.style;
                this.order.embroidery[rowId] = data.embroidery;
                this.order.measurement[rowId] = data.measurement;
                this.order.bodyDescription[rowId] = data.bodyDescription;
              }

              // 显示
              row.toggleDetails();
            })
            .catch(err => {
              console.error(err);
            });
      } else {
        // 隐藏
        row.toggleDetails();
      }
    },
    showStatusModel(row) {
      this.current.order = row.item;

      this.$bvModal.show('modal-status');
    },
    handleStatus() {
      if (this.current.order.status === this.current.order.statusOld) {
        this.$bvToast.toast('status has not changed', {
          title: '系统提示',
          variant: 'danger',
          solid: true
        });
        return;
      }

      this.$bvModal.msgBoxConfirm('Are you sure want to update order status?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'info',
        okTitle: 'Confirm',
        cancelTitle: 'Cancel'
      })
          .then((value) => {
            if (value) {
              this.current.loading = true;

              this.$axios.put('order/status/' + this.current.order.id, {
                status: this.current.order.status
              })
                  .then(res => {
                    this.current.loading = false;

                    this.$nextTick(() => {
                      this.$bvModal.hide('modal-status');
                    });

                    if (res.s === 0) {
                      this.$bvToast.toast('操作成功：' + res.data, {
                        title: '系统提示',
                        variant: 'success',
                        solid: true
                      });
                    } else {
                      this.$bvToast.toast(res.msg, {
                        title: '系统提示',
                        variant: 'danger',
                        solid: true
                      });
                    }

                    this.loadPage();
                  })
                  .catch(err => {
                    this.current.loading = false;

                    console.error(err);
                  });
            }
          })
          .catch(e => {
            console.error(e);
          });
    }
  },
  mounted() {
    this.loadPage();
  }
};
</script>

<style lang="scss">
.order-manage {
  width: 100%;

  .header-title {
    color: cornflowerblue;
    font-weight: bold;
    padding-left: 10px;
  }

  label {
    &.title {
      font-weight: bold;
      padding-right: 5px;
    }
  }
}
</style>